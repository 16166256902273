import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./App.css"

function App() {
  const handle = useFullScreenHandle();
  const [showModal, setShowModal] = React.useState(false);
  const [idleTimer, setIdleTimer] = React.useState(null);
  const togglePopup = () => {
    setShowModal(!showModal);
  }

  const timeout = 120000
  const [remaining, setRemaining] = useState(timeout)
  const [isIdle, setIsIdle] = useState(false)
  const handleOnIdle = () => setIsIdle(true)

  const {
    getRemainingTime,
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  useEffect(() => {
    setRemaining(getRemainingTime())
    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  }, [])

  return (
    <>
      <div className="fullscreen_btn"><button className="cursor-pointer" onClick={handle.enter}>Click here to start Techstars Websummit Experience</button></div>
      <FullScreen handle={handle}>
        <div className="main" onClick={togglePopup}>
          <div
            className="large_screen"
            dangerouslySetInnerHTML={{ 
              __html: `
                <video loop muted autoplay playsinline width="100%" height="100%">
                  <source src="https://fs-garage-assets.s3.us-east-2.amazonaws.com/techstars/showcase/Techstars-WebSummit_TouchScreen+16-9+4K.webm" class="w-full" /> 
                </video>
              ` 
            }}
          >
          </div>
          <div
            className="small_screen"
            dangerouslySetInnerHTML={{ 
              __html: `
                <video loop muted autoplay playsinline width="100%" height="100%">
                  <source src="https://fs-garage-assets.s3.us-east-2.amazonaws.com/techstars/showcase/Techstars-WebSummit_TouchScreen+9-16+4K.webm" class="w-full" /> 
                </video>
              ` 
            }}
          >
          </div>
        </div>
        {remaining && showModal && (
            <div className="popup">
              <div className="popup_box">
                <div className="popup_close" style={{position: "absolute",height: "24px",padding: "8px 10px 12px",right: "30px"}} onClick={togglePopup}>x</div>
                <div className="popup_content" style={{height: "100vh"}}>
                  <iframe title="airtable embed" className="airtable-embed" src="https://airtable.com/embed/shrBxVKXJSOG8BMdJ?backgroundColor=red&viewControls=on" frameBorder="0" width="100%" style={{background: "transparent", border: "1px solid #ccc", height: "100vh"}}></iframe>
                </div>
              </div>
            </div>
        )}
      </FullScreen>
    </>
  );
}

export default App;